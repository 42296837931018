<template>
  <div class="website-content">
    <div class="website_banner relative">
      <swiper class="swiper-wrapper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="website_banner_box swiper-slide">
          <div class="left">
            <div class="banner_bottom_height">
              <span class="title">{{ roomsnum }}家民宿</span>
              <span class="title" style="width: 450px">​都在使用的民宿管理系统</span>
            </div>
            <div class="register_box" @click="handleRouterYzg">
              <span class="register_text">现在注册，免费领取30天高级会员</span>
              <i class="icon_arrow_go"></i>
            </div>
          </div>
          <div class="right-banner">
            <img
              src="../assets/pic/indexBanner01.png"
              alt=""
              style="width: 640px"
            />
          </div>
        </swiper-slide>
        <swiper-slide class="website_banner_box swiper-slide">
          <div class="left">
            <div class="banner_bottom_height">
              <span class="title">​云掌柜渠道直连</span>
              <span class="swiper_banner_text"
                >已直连国内外20+预订平台 房态同步高效管理</span
              >
            </div>
            <div class="register_box" @click="handleRouterYzg">
              <span class="register_text">现在注册，免费领取30天高级会员</span>
              <i class="icon_arrow_go"></i>
            </div>
          </div>
          <div class="right-banner">
            <img src="../assets/pic/indexBanner02.png" alt="" style="width: 640px" />
          </div>
        </swiper-slide>
        <swiper-slide class="website_banner_box swiper-slide">
          <div class="left">
            <div class="banner_bottom_height">
              <div class="f-r-c-c">
                <span class="title">​云掌柜</span>
                <span class="concat_text">X</span>
                <span class="title">湖南广电</span>
              </div>
              <span class="swiper_banner_text text-width"
              >携手打造民宿版《向往的生活》 送你免费上电视</span
              >
            </div>
            <div class="register_box" @click="handleRouterYzg">
              <span class="register_text">现在注册，免费领取30天高级会员</span>
              <i class="icon_arrow_go"></i>
            </div>
          </div>
          <div class="right-banner">
            <img src="../assets/pic/indexBanner04.png" alt="" style="width: 640px" />
          </div>
        </swiper-slide>
        <swiper-slide class="website_banner_box swiper-slide">
          <div class="left">
            <div class="banner_bottom_height">
              <div class="f-r-c-c">
                <span class="title">​云掌柜</span>
                <span class="concat_text">X</span>
                <span class="title">原乡里</span>
              </div>
              <span class="swiper_banner_text text-width"
              >国内高端连锁品牌 北京区域标杆民宿</span
              >
            </div>
            <div class="register_box" @click="handleRouterYzg">
              <span class="register_text">现在注册，免费领取30天高级会员</span>
              <i class="icon_arrow_go"></i>
            </div>
          </div>
          <div class="right-banner">
            <img src="../assets/pic/indexBanner03.png" alt="" style="width: 640px" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="pagination_banner">
        <div class="banner_dot_box">
          <div class="dot dot_act"></div>
        </div>
      </div>
    </div>
    <div class="gray_bg_line"></div>
    <div class="positions_banner">
      <i class="icon_banner_left_arrow" slot="button-prev" @click="handlePrev"></i>
      <div class="banner_content swiper-container">
        <!-- <div class="swiper-wrapper verBanner">
          <div class="banner_item swiper-slide">
            <img
              src="../assets/pic/verBanner01.png"
              alt=""
              class="icon_left_banner"
            />
            <div class="ml32 right_text">
              <p class="info">版本更新 | 房态V9.0正式上线！</p>
              <p class="bottom">
                <span>点击查看</span>
                <span>1/3</span>
              </p>
            </div>
          </div>
          <div class="banner_item swiper-slide">
            <img
              src="../assets/pic/verBanner02.png"
              alt=""
              class="icon_left_banner"
            />
            <div class="ml32 right_text">
              <p class="info">版本更新 | 全新微官网&商超重磅上线！</p>
              <p class="bottom">
                <span>点击查看</span>
                <span>2/3</span>
              </p>
            </div>
          </div>
          <div class="banner_item swiper-slide">
            <img
              src="../assets/pic/verBanner03.png"
              alt=""
              class="icon_left_banner"
            />
            <div class="ml32 right_text">
              <p class="info">版本更新 | 新版云掌柜PMS正式上线！</p>
              <p class="bottom">
                <span>点击查看</span>
                <span>3/3</span>
              </p>
            </div>
          </div>
        </div> -->
        <swiper class="swiper-wrapper verBanner" ref="mySwiper1">
          <swiper-slide class="banner_item swiper-slide" v-for="(item, index) in bannerData" :key="index">
            <img
                :src="item.img"
                alt="banner"
                class="icon_left_banner"
                @click="handleBannerRouter(item.type)"
            />
            <div class="ml32 right_text">
              <p class="info">{{ item.title }}</p>
              <p class="bottom" style="justify-content: right">
              <span @click="handleBannerRouter(item.type)" class="pointer">点击查看</span>
                <span>{{ index + 1 }}/3</span>
              </p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <i class="icon_banner_right_arrow" slot="button-next" @click="handleNext"></i>
    </div>
    <div class="shop-banner">
      <swiper class="price-list swiper-wrapper verBannerBanner"
        :options="verBannerOptions"
      >
        <swiper-slide class="swiper-slide">
          <img
            src="../assets/pic/verBanner01.png"
            style="width: 240px; height: 102px"
          />
          <p class="banner_top_desc">最新动态 | 抖音与云掌柜签约民宿区域独家合作，共同布局本地生活</p>
          <p class="banner_top_service">点击查看</p>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <img
            src="../assets/pic/verBanner02.jpeg"
            style="width: 240px; height: 102px"
          />
          <p class="banner_top_desc">版本更新 | 新版小程序上线！抢滩私域流量主阵地</p>
          <p class="banner_top_service">点击查看</p>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <img
            src="../assets/pic/verBanner03.jpeg"
            style="width: 240px; height: 102px"
          />
          <p class="banner_top_desc">版本更新 | 携程直连重构！让掌柜们更省心、更安心、更放心</p>
          <p class="banner_top_service">点击查看</p>
        </swiper-slide>
      </swiper>
    </div>
    <div class="selling-point">
      <div class="item-feature fade">
        <div class="text_box">
          <p class="feature-title">管好店</p>
          <div class="ml27 goods_label">
            <div>
              <p class="yzg_header_label">房态管理</p>
              <p class="yzg_header_desc">
                表格化房态页面操作简单，数据云端存储
              </p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">​财务统计</p>
              <p class="yzg_header_desc">智能分析民宿运营状况，让对账更清晰</p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">扩展功能</p>
              <p class="yzg_header_desc">
                功能多样开通简单，订制个性化管理工具
              </p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">商超管理</p>
              <p class="yzg_header_desc">
                打造民宿经营多业态，助力提升二销收入
              </p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">渠道直连</p>
              <p class="yzg_header_desc">
                已打通20+个预订平台，直接落单房态同步
              </p>
            </div>
          </div>
          <div class="learn-more-box">
            <router-link to="/yzgInstructions"
              tag="a"
              target='_blank' class="theme-a-more">了解更多</router-link>
            <i class="icon-learn-more"></i>
          </div>
        </div>
        <img
          src="../assets/pic/indexInnInfo01.png"
          alt=""
          class="feature_img"
        />
      </div>
      <div class="item-feature fade">
        <img
          src="../assets/pic/indexInnInfo02.png"
          alt=""
          class="feature_img"
        />
        <div class="text_box">
          <p class="feature-title">多卖房</p>
          <div class="ml27 goods_label">
            <div>
              <p class="yzg_header_label">微信营销</p>
              <p class="yzg_header_desc">微官网，小程序，打造“0佣金OTA”​</p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">​预售营销</p>
              <p class="yzg_header_desc">快速预售淡季库存，提高全年入住率</p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">会员营销</p>
              <p class="yzg_header_desc">多种方法维护客人关系，提高复购率</p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">网站营销</p>
              <p class="yzg_header_desc">手把手教你获取流量，提高网站转化率​</p>
            </div>
            <div class="mt24">
              <p class="yzg_header_label">房源分销</p>
              <p class="yzg_header_desc">一键分销至多个预订平台，增加订单量</p>
            </div>
          </div>
          <div class="learn-more-box">
            <router-link to="/yzgInstructions"
              tag= 'a'
              target='_blank' class="theme-a-more">了解更多</router-link>
            <i class="icon-learn-more"></i>
          </div>
        </div>
      </div>
      <div class="item-feature fade">
        <div class="text_box" style="margin-top: 88px">
          <p class="feature-title">建生态</p>
          <div class="ml27 goods_label">
            <div class="mt24 ">
              <div class="inn_status_flex">
                <p class="yzg_header_label">民宿学苑</p>
                <p class="yzg_header_desc" style="color: #8C8C8C;">- 民宿充电站</p>
              </div>
              <p class="goods_label_init">
                让爱学习的民宿人更有钱赚！
                <br>
                包含：线上课程、线下沙龙、大咖私享会</p>
            </div>
            <div class="mt24 ">
              <div class="inn_status_flex">
                <p class="yzg_header_label">​民宿集群</p>
                <p class="yzg_header_desc" style="color: #8C8C8C;">- 数字化管理服务方案</p>
              </div>
              <p class="goods_label_init">
                提升民宿管理整体数字信息化水平
                <br>
                包含以下服务项目：目的地品牌营销、
                <br>
                目的地流量运营、目的地大数据、
                <br>
                目的地招商、目的地生态
              </p>
            </div>

          </div>
          <div class="learn-more-box">
            <router-link to="/yzgInstructions"
              tag='a'
              target='_blank' class="theme-a-more">了解更多</router-link>
            <i class="icon-learn-more"></i>
          </div>
        </div>
        <img
          src="../assets/pic/indexInnInfo03.png"
          alt=""
          class="feature_img"
        />
      </div>
    </div>
    <div class="serve_flow_box fade">
      <p class="title">完善的服务流程</p>
      <div class="serve_list_box">
        <div class="flow_icon">
          <div class="flow_item_box">
            <i class="icon_msg_flow"></i>
          </div>
          <i class="icon_line_top"></i>
          <div class="flow_item_box">
            <i class="icon_gift_flow"></i>
          </div>
          <i class="icon_line_bottom"></i>
          <div class="flow_item_box">
            <i class="icon_heat_flow"></i>
          </div>
        </div>
      </div>
      <div class="flow_text_box">
        <div>
          <p class="f-r-s-c bottom_size">
            <span class="flow_title">售前</span>
          </p>
          <p class="">
            <span class="flow_desc">经营状况分析、需求梳理、产品介绍​</span>
          </p>
        </div>
        <div>
          <p class="f-r-c-c bottom_size">
            <span class="flow_title">售中</span>
          </p>
          <p>
            <span class="flow_desc" style="margin-left: 48px"
              >解决方案、案例展示</span
            >
          </p>
        </div>
        <div>
          <p class="f-r-c-c bottom_size">
            <span class="flow_title " style="margin-left: 90px;">售后</span>
          </p>
          <p>
            <span class="flow_desc" style="margin-left: 90px;width: 165px;"
              >一对一使用培训​、997客服在线、功能服务持续迭代</span
            >
          </p>
        </div>
      </div>
      <div class="media_serve_box">
        <div class="f-r-s-s mt48">
          <div class="flow_item_box">
            <i class="icon_msg_flow"></i>
          </div>
          <div class="ml16 f-c-c-s">
            <p class="flow_title">售前</p>
            <p class="flow_desc">
              经营状况分析、需求梳理、
              <br />
              产品介绍​
            </p>
          </div>
        </div>
        <div class="f-r-s-s mt48">
          <div class="flow_item_box">
            <i class="icon_gift_flow"></i>
          </div>
          <div class="ml16 f-c-c-s">
            <p class="flow_title">售中</p>
            <p class="flow_desc">解决方案、案例展示</p>
          </div>
        </div>
        <div class="f-r-s-s mt48">
          <div class="flow_item_box">
            <i class="icon_heat_flow"></i>
          </div>
          <div class="ml16 f-c-c-s">
            <p class="flow_title">售后</p>
            <p class="flow_desc">
              一对一使用培训​、997客服在
              <br />
              线、功能服务持续迭代
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import banner01 from "@/assets/pic/verBanner01.png";
import banner02 from "@/assets/pic/verBanner02.jpeg";
import banner03 from "@/assets/pic/verBanner03.jpeg";
import  axios from 'axios'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      bannerData: [
        { title: "中国住宿业2023年度数据报告暨2024元旦假期预测发布！", img: banner01, type: "roomStatus" },
        { title: "抖音代运营服务上新 | 服务商家GMV超百万，核销率最高88%", img: banner02 ,  type: "marketing" },
        { title: "OTA代运营 | 合作1个月就跑赢大盘跨越爬坡期，这家新店怎么这么牛?", img: banner03 , type: "tiktok"},
      ],
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        effect: 'fade',
        pagination: {
            el: '.banner_dot_box',
        },
      },
      verBannerOptions:{
        slidesPerView:1.3,
        spaceBetween:16,
        paginationClickable: true,
      },

      // 总的房间数量
      roomsnum: ''
    };
  },
  methods: {
    handleRouter() {
      window.open("https://v2.yunzhanggui.net/user/login", "_blank");
    },
    handlePrev(){
      this.$refs.mySwiper1.$swiper.slidePrev()
    },
    handleNext(){
      this.$refs.mySwiper1.$swiper.slideNext()
    },
    handleBannerRouter(type){
      console.log(type,'type');
      switch(type) {
        case 'roomStatus':
          window.open('https://mp.weixin.qq.com/s/3SpucjpKmvR5xwBqIGGp5A','_blank')
          break;
        case 'marketing':
          window.open('https://mp.weixin.qq.com/s/kAn54CcRq329tMZRnDaIQw','_blank')
          break;
        case 'tiktok':
          window.open('https://mp.weixin.qq.com/s/TB5ivHvs93UpgDk4ScrueA','_blank')
          break
      }
    },
    handleRouterYzg(){
      window.open("https://v2.yunzhanggui.net/user/login", "_blank");
    },

    // 获取总房间数
   async getRooms() {
      try {
        const res = await
            axios.get('https://api.yunzhanggui.net/api/v1/inn/get_hotel_total?version=9.3.4.0',{ua:"pms"})
        this.roomsnum = res.data.ret_data.total
      }
      catch (error) {
        return
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  props: {},
  watch: {},
 created() {
    // 获取总房间数
    this.getRooms()
  },
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.pagination_banner >>> .swiper-pagination-bullet{
  width: 7px;
  height: 7px;
  background:#000000;
  border-radius: 6px;
  margin-right: 16px;
}
.pagination_banner >>>  .swiper-pagination-bullet-active{
  width: 24px;
  height: 3px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: relative;

}
.pagination_banner >>>  .swiper-pagination-bullet-active:after{
  display: block;
  content:"";
  width: 24px;
  height: 3px;
  background:#00796B;
  border-radius: 8px;
  transition: width 5s;
}
.bottom_size {
  margin-bottom: 0;
}
.positions_banner .banner_content .banner_item{
  cursor: default;
}
</style>
